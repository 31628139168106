const pako = require("pako");

function keyBase64(ShareRoute) {
    try {
        const strData = atob(ShareRoute);
        const charData = strData.split("").map((c) => c.charCodeAt(0));
        const binData = new Uint8Array(charData);
        const data = pako.inflate(binData, {to: "string"});
        return data;
    } catch (error) {
        console.error("Error al parsear los datos:", error);
        return null;
    }
}

function formatTime(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString();
}

function formatDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    return `${minutes} minutos`;
}

function formatPrice(price) {
    return price ? `$ ${price.toLocaleString('es-CO')}` : "-";
}

export const utils = {
    formatDuration,
    formatPrice,
    formatTime,
    keyBase64,
};
