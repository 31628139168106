export const PUBLIC_KEY = 'BLwyeX20Hb8mXigGRApIJh2GbeNmCWgYPf_0-_7L0cel1a7GzdfSxSaOUFoLg5n4TcXlhtK0yntOhKJy5OtHw2M'
export const MOVING_MARKER = require("@/assets/images/movingTaxi.svg")
export const MARKER = {
    taxiOnRoad: {
        url: require("@/assets/images/service_resume/taxiontheroad.svg")
    },
    pickedUp: {
        url: require("@/assets/images/service_resume/wepickedyouup.svg")
    },
    destiny: {
        url: require("@/assets/images/service_resume/wearrived.svg")
    }
}
export const STATE_DESCRIPTIONS = {
    200: "Tu taxi va en camino a recogerte",
    540: "Tu taxi te recogió y vamos en camino a tu destino",
    900: "Llegaste a tu destino"
}
export const STATE_TO_ICON = {
    200: MARKER.taxiOnRoad,
    540: MARKER.pickedUp,
    900: MARKER.destiny
}
export const STATE_ICONS = {
    200: require("@/assets/images/service_resume/circle_blue.svg"),
    540: require("@/assets/images/service_resume/circle_orange.svg"),
    900: require("@/assets/images/service_resume/circle_green.svg")
}
export const ASSIGNED_TAXI = 200
export const POSITIVE_TAXI = 540
export const FINISHED_TAXI = 900





