export default class ServiceInformation {
    date = 0
    taxi = 0
    state = 0
    latitude = 0
    longitude = 0

    static fromJSONResponse(json) {
        return new ServiceInformation()
            .setDate(json.Date)
            .setTaxi(json.Taxi)
            .setState(json.State)
            .setLatitude(json.Latitude)
            .setLongitude(json.Longitude)
    }

    getDate() {
        return this.date
    }

    getHour() {
        const hours = new Date(this.date * 1000)
        return hours.toLocaleTimeString('es-Es', {hour: '2-digit', minute: '2-digit', hour12: true})
    }

    setDate(value) {
        this.date = value
        return this
    }

    getTaxi() {
        return this.taxi
    }

    setTaxi(value) {
        this.taxi = value
        return this
    }

    getState() {
        return this.state
    }

    setState(value) {
        this.state = value
        return this
    }

    getLatitude() {
        return this.latitude
    }

    setLatitude(value) {
        this.latitude = value
        return this
    }

    getLongitude() {
        return this.longitude
    }

    setLongitude(value) {
        this.longitude = value
        return this
    }
}