<template>
  <div class="l-container-resume">
    <div class="l-service-resume" ref="resumeContainer">
      <div class="l-service-resume__details">
        <img src="../../../assets/images/service_resume/logo.svg" alt="logo">
        <p class="l-service-resume__details-title">Resumen de tu servicio</p>
        <div>
          <p class="l-service-resume__details-label">Usuario:</p>
          <p class="l-service-resume__details-text"> {{ serviceDetails?.user }}</p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Te recogimos en:</p>
          <p class="l-service-resume__details-text">
            {{ serviceDetails?.origin?.name_address ? `${serviceDetails?.origin?.name_address} · ` : '' }}
            {{ serviceDetails?.origin?.address }}, {{ serviceDetails?.origin?.neighborhood }} ·
            {{ serviceDetails?.origin?.city }}</p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Te llevamos a:</p>
          <p class="l-service-resume__details-text">
            {{ serviceDetails?.destination?.name_address ? `${serviceDetails?.destination?.name_address} · ` : '' }}
            {{
              serviceDetails?.destination?.address !== serviceDetails?.destination?.neighborhood ? `${serviceDetails?.destination?.address}, ` : ''
            }}{{ serviceDetails?.destination?.neighborhood }} ·
            {{ serviceDetails?.destination?.city }}
          </p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Tu servicio costó:</p>
          <p class="l-service-resume__details-text"> {{ utils.formatPrice(serviceDetails?.price) }}</p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Pagaste con:</p>
          <p class="l-service-resume__details-text"> {{ serviceDetails?.payment_method }}</p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Tiempo · Distancia:</p>
          <p class="l-service-resume__details-text"> {{ serviceDetails?.duration }} min ·
            {{ parseFloat(serviceDetails?.distance).toFixed(1) }} Km </p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Conductor:</p>
          <p class="l-service-resume__details-text">{{ serviceDetails?.driver }}</p>
        </div>
        <div>
          <p class="l-service-resume__details-label">Vehículo:</p>
          <p class="l-service-resume__details-text">{{ serviceDetails?.plate }}</p>
        </div>
      </div>
      <div class="l-service-resume__container-map">
        <div id="map" ref="mapContainer" class="l-service-resume__map"></div>
      </div>
      <div class="l-service-resume__service-status-container">
        <div class="l-service-resume__service-status"
             v-for="(state, index) in serviceDetails?.states"
             :key="index"
        >
          <img
              :src="getStateIcon(state.state)"
              alt="circle"
          >
          <div>
            <p class="l-service-resume__details-label">{{ utils.formatTime(state.time) }}</p>
            <p class="l-service-resume__details-text">{{ getStateDescription(state.state) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {STATE_DESCRIPTIONS, STATE_ICONS} from "@/shared/constants";
import {utils} from "@/shared/utils";
import html2canvas from "html2canvas";
import "ol/ol.css"; // Importa los estilos de OpenLayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import {fromLonLat} from "ol/proj";
import {Feature} from "ol";
import {LineString, Point} from "ol/geom";
import {Icon, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {boundingExtent} from "ol/extent";
import {defaults as defaultControls} from 'ol/control';
import XYZ from 'ol/source/XYZ'
import {mapActions} from "vuex";

export default {
  name: "ServiceResume",
  computed: {
    utils() {
      return utils
    }
  },
  data() {
    return {
      serviceDetails: null,
      map: null,
      userMarker: null,
      movingMarker: null,
      markerIndex: 0,
      polyline: null,
      route: []
    }
  },
  methods: {
    getStateDescription(stateCode) {
      return STATE_DESCRIPTIONS[stateCode] || "Estado desconocido"
    },

    getStateIcon(stateCode) {
      return STATE_ICONS[stateCode] || ''
    },

    async initMap() {
      this.map = new Map({
        target: this.$refs.mapContainer, // Usa ref en Vue 2
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
            }),
          }),
        ],
        view: new View({
          center: fromLonLat([this.serviceDetails.states[1]?.longitude, this.serviceDetails.states[1]?.latitude]),
          zoom: 12,
        }),
        controls: defaultControls({zoom: false}),
      });
    },
    addMarker() {
      const markers = []
      if (this.serviceDetails.states) {
        this.serviceDetails.states.forEach((state) => {
          const marker = new Feature({
            geometry: new Point(fromLonLat([parseFloat(state.longitude), parseFloat(state.latitude)])), // Nueva York
          });

          marker.setStyle(
              new Style({
                image: new Icon({
                  src: STATE_ICONS[state.state],
                  scale: 1,
                  // anchor: [0.5, 1],
                }),
              })
          );

          markers.push(marker)
        })
      }

      const vectorSource = new VectorSource({
        features: markers,
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      this.map.addLayer(vectorLayer);
    },
    drawRoute() {
      const routeCoords = this.route.map((point) => (fromLonLat([point.longitude, point.latitude])))

      if (routeCoords.length < 2) {
        return
      }

      const routeFeature = new Feature({
        geometry: new LineString(routeCoords),
      });

      routeFeature.setStyle(
          new Style({
            stroke: new Stroke({
              color: "#0000FF",
              width: 4,
            }),
          })
      );

      const routeSource = new VectorSource({
        features: [routeFeature],
      });

      const routeLayer = new VectorLayer({
        source: routeSource,
      });

      this.map.addLayer(routeLayer);

      const extent = boundingExtent(routeCoords);
      this.map.getView().fit(extent, {padding: [20, 20, 20, 20]});
    },
    async captureScreen() {
      try {
        const element = this.$refs.resumeContainer
        const canvas = await html2canvas(element, {
          useCORS: true, // Necesario si hay imágenes externas
          logging: false, // Desactiva logs en consola
        })

        const base64Image = canvas.toDataURL('image/png');

      } catch (error) {
        console.error('Error al capturar:', error);
      }
    },
    ...mapActions('fcmStore', ['findRoute'])
  },
  async mounted() {
    const value = window.location.search
    const urlParams = new URLSearchParams(value)
    let base64Data = urlParams.get('data')
    if (base64Data) {
      try {
        base64Data = base64Data.replaceAll(' ', '+')
        this.serviceDetails = await JSON.parse(utils.keyBase64(base64Data))

        await this.initMap()
        this.route = await this.findRoute({
          city: this.serviceDetails.city,
          serviceId: this.serviceDetails.service_id,
        })

        this.drawRoute()
        this.addMarker()
      } catch (error) {
        console.error("Error al parsear los datos:", error)
      }
    }
  }
}
</script>

