export default class ShareRouteUc {
    #repository = null

    constructor(repository) {
        this.#repository = repository
    }

    async subscribeToken() {
        try {
            await this.#repository.sendToken()
        } catch (error) {
            throw new Error(error)
        }
    }

    async requestShareFirestore(city, serviceID) {
        try {
            this.#repository.requestShareFirestore(city, serviceID)
        } catch (error) {
            throw new Error(error)
        }
    }

    async findRoute(city, serviceID) {
        try {
            const response = await this.#repository.findRoute(city, serviceID)

            return {route: response.data}
        } catch (error) {
            throw new Error(error)
        }
    }
}