<template>
  <div>
    <div id="map" ref="mapContainer" class="l-container__shared-route__map"></div>
  </div>

</template>

<script>
import {mapMutations, mapState} from "vuex";
import notifications from "@/shared/notifications";
import {MOVING_MARKER, STATE_ICONS} from "@/shared/constants";
import "ol/ol.css"; // Importa los estilos de OpenLayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import {fromLonLat} from "ol/proj";
import {Feature} from "ol";
import {LineString, Point} from "ol/geom";
import {Icon, Stroke, Style} from "ol/style";
import {boundingExtent} from "ol/extent";
import {defaults as defaultControls} from 'ol/control';
import XYZ from 'ol/source/XYZ'
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

export default {
  name: "MapRoute",
  data() {
    return {
      map: '',
      movingMarker: null,
      polyline: null,
      lastAssignedTaxiMarker: null,
      lastPositiveTaxiMarker: null,
      lastTaxiFinishedMarker: null
    }
  },
  computed: {
    ...mapState('fcmStore', ['shareRoute', 'loading'])
  },
  watch: {
    shareRoute: {
      handler(value) {
        if (value.isSharingRoute === 'false') {
          notifications.confirmation('La ruta ya no se comparte', 'warning', () => {
            window.location.href = 'https://www.radiotaxislibres.com'
          })
          return
        }

        const coordinates = value.getRoute().map((point) => (fromLonLat([point.longitude, point.latitude])))
        this.updateRoute(coordinates)
        this.updateMarkers(value)
        this.setLoading(false)
      },
      deep: true,
    }
  },
  methods: {
    updateRoute(coordinates) {
      if (coordinates.length === 0) return

      this.drawRoute(coordinates);
      this.fitZoomBounds(coordinates);
    },

    drawRoute(coordinates) {
      if (this.polyline) {
        this.map.removeLayer(this.polyline);
      }

      const routeFeature = new Feature({
        geometry: new LineString(coordinates),
      });

      routeFeature.setStyle(
          new Style({
            stroke: new Stroke({
              color: "#0000FF",
              width: 4,
            }),
          })
      );

      const routeSource = new VectorSource({
        features: [routeFeature],
      });

      this.polyline = new VectorLayer({
        source: routeSource,
      });

      this.map.addLayer(this.polyline);

      if (this.movingMarker) {
        this.map.removeLayer(this.movingMarker);
      }

      const marker = new Feature({
        geometry: new Point(coordinates[coordinates.length - 1]),
      });

      marker.setStyle(
          new Style({
            image: new Icon({
              src: MOVING_MARKER,
              scale: 1,
            }),
          })
      );

      const vectorSource = new VectorSource({
        features: [marker],
      });

      this.movingMarker = new VectorLayer({
        source: vectorSource,
      });

      this.map.addLayer(this.movingMarker);
    },
    fitZoomBounds(coordinates) {
      const extent = boundingExtent(coordinates);
      this.map.getView().fit(extent, {padding: [50, 50, 50, 50]});
    },

    updateMarkers(value) {
      if (this.lastAssignedTaxiMarker) this.map.removeLayer(this.lastAssignedTaxiMarker);
      if (this.lastPositiveTaxiMarker) this.map.removeLayer(this.lastPositiveTaxiMarker);
      if (this.lastTaxiFinishedMarker) this.map.removeLayer(this.lastTaxiFinishedMarker);

      if (value.isAssignedTaxi()) {
        this.lastAssignedTaxiMarker = this.addMarker(value.getLastAssignedTaxi().getLongitude(), value.getLastAssignedTaxi().getLatitude(), STATE_ICONS[value.getLastAssignedTaxi().getState()]);
      }

      if (value.isPositiveTaxi()) {
        this.lastPositiveTaxiMarker = this.addMarker(value.getLastPositiveTaxi().getLongitude(), value.getLastPositiveTaxi().getLatitude(), STATE_ICONS[value.getLastPositiveTaxi().getState()]);
      }

      if (value.isTaxiFinished()) {
        this.lastTaxiFinishedMarker = this.addMarker(value.getLastTaxiFinished().getLongitude(), value.getLastTaxiFinished().getLatitude(), STATE_ICONS[value.getLastTaxiFinished().getState()]);
      }
    },

    addMarker(lon, lat, iconSrc) {
      const marker = new Feature({
        geometry: new Point(fromLonLat([lon, lat])),
      });

      marker.setStyle(
          new Style({
            image: new Icon({
              src: iconSrc,
              scale: 1,
              // anchor: [0.5, 1],
            }),
          })
      )

      const vectorSource = new VectorSource({
        features: [marker],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      this.map.addLayer(vectorLayer);

      return vectorLayer;
    },
    ...mapMutations('fcmStore', ['setLoading'])
  },

  mounted() {
    this.map = new Map({
      target: this.$refs.mapContainer, // Usa ref en Vue 2
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([-73.126112, 7.112581]),
        zoom: 12,
      }),
      controls: defaultControls({zoom: false}),
    });
  }
}
</script>


