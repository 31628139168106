<template>
  <div class="l-container__shared-route__tours-details" :class="{'is-open': isDropdownOpen}">
    <div @click="toggleDropdown" class="l-container__shared-route__tours-details__header">
      <p class="l-container__shared-route__tours-details__header-title">Ver detalle del recorrido</p>

      <img v-if="isDropdownOpen" src="@/assets/images/arrowUp.svg" alt="arrow">
      <img v-else src="@/assets/images/arrow.svg" alt="arrow">
    </div>
    <div class="l-container__shared-route__tours-details__body" v-show="isDropdownOpen"
         :class="{'is-open': isDropdownOpen}">
      <div
          class="l-container__shared-route__tours-details__body-item"
          v-if="isAssignedTaxi"
      >
        <img src="@/assets/images/service_resume/circle_blue.svg" alt="assigned">
        <div>
          <p class="l-container__shared-route__tours-details__body-text">
            {{ shareRoute.getLastAssignedTaxi().getHour() }}</p>
          <p>Tu taxi va en camino a recogerte</p>
        </div>
      </div>
      <div
          class="l-container__shared-route__tours-details__body-item"
          v-if="isLastPositiveTaxi"
      >
        <img src="@/assets/images/service_resume/circle_orange.svg" alt="positive">
        <div>
          <p class="l-container__shared-route__tours-details__body-text">
            {{ shareRoute.getLastPositiveTaxi().getHour() }}</p>
          <p>Tu taxi te recogió y vamos en camino a tu destino</p>
        </div>

      </div>
      <div
          class="l-container__shared-route__tours-details__body-item"
          v-if="isLastTaxiFinished"
      >
        <img src="@/assets/images/service_resume/circle_green.svg" alt="finished">
        <div>
          <p class="l-container__shared-route__tours-details__body-text">
            {{ shareRoute.getLastTaxiFinished().getHour() }}</p>
          <p>Llegaste a tu destino</p>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "ToursDetails",
  data() {
    return {
      isDropdownOpen: false
    }
  },
  computed: {
    isAssignedTaxi() {
      return this.shareRoute.isAssignedTaxi()
    },
    isLastPositiveTaxi() {
      return this.shareRoute.isPositiveTaxi()
    },
    isLastTaxiFinished() {
      return this.shareRoute.isTaxiFinished()
    },

    ...mapState('fcmStore', ['shareRoute'])
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }
}
</script>

