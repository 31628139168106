<template>
  <div class="l-container">
    <div class="spinner-container">
      <div v-if="loading" class="spinner">
        <div class="loader"></div>
      </div>
      <div class="l-container__shared-route">
        <information-card/>
        <map-route/>
        <tours-details/>
      </div>
      <enable-notifications/>
      <blocked-notifications/>
    </div>
  </div>
</template>
<script>
import MapRoute from "@/share_route/context/components/MapRoute.vue"
import {mapActions, mapMutations, mapState} from "vuex"
import 'firebase/messaging'
import InformationCard from "@/share_route/context/components/InformationCard.vue";
import EnableNotifications from "@/share_route/context/components/EnableNotifications.vue";
import BlockedNotifications from "@/share_route/context/components/BlockedNotifications.vue";
import ToursDetails from "@/share_route/context/components/ToursDetails.vue";


export default {
  name: "ShareRoutes",
  components: {ToursDetails, BlockedNotifications, EnableNotifications, InformationCard, MapRoute},
  data() {
    return {
      serviceId: '',
      city: '',
    }
  },
  computed: {
    ...mapState('fcmStore', ['timer', 'shareRoute', 'loading'])
  },
  methods: {
    ...mapMutations('fcmStore', ['setTimer', "setLoading"]),
    ...mapActions('fcmStore', ['subscribeFCMToken', 'subscribeRouteTopic', 'requestShareFirestore', 'clearTimer', 'updateRoute', 'deleteRoute']),
  },
  async mounted() {
    const value = window.location.search
    const urlParams = new URLSearchParams(value)
    this.serviceId = urlParams.get('serviceId')
    this.city = urlParams.get('cityId')

    await this.requestShareFirestore({city: this.city, serviceId: this.serviceId})
    await this.subscribeRouteTopic({city: this.city, serviceId: this.serviceId, scope: this})

    this.$bus.$on('clearTimer', this.clearTimer)
    this.$bus.$on('updateRoute', this.updateRoute)
    this.$bus.$on('deleteRoute', this.deleteRoute)
  }
}
</script>