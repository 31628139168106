<template>
  <div class="information-card">
      <p class="information-card__plate">{{shareRoute.getPlate() || '------' }} </p>
      <div>
        <p>Conductor</p>
        <p class="text-name">{{shareRoute.getName()}}</p>
      </div>
      <div class="information-card__container-share">
        <button @click="makeCall" class="button-phone">
          <img  src="@/assets/images/phone.svg" alt="phone">
        </button>
        <p class="text-phone" v-if="!showPhoneNumber && phoneNumber">{{ phoneNumber }}</p>
        <button @click="shareURL" class="button-phone">
          <img class="image-shared" src="@/assets/images/shared.svg" alt="shared">
        </button>
      </div>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "InformationCard",
  data() {
    return {
      phoneNumber:'',
      showPhoneNumber: false,
    }
  },
  computed: {
    ...mapState('fcmStore', ['shareRoute'])
  },
  methods:{
    async shareURL() {
      const shareData = {
        title: "Ruta Servicio",
        text: "Ingresa para ver el recorrido del conductor",
        url: document.location.href,
      };

      if (navigator.share) {
        try {
          await navigator.share(shareData);
        } catch (error) {
          console.error("Error al compartir la URL:", error);
          window.location.href = document.location.href;
        }
      } else {
        window.location.href = document.location.href;
      }
    },

    makeCall() {
      const phoneNumber = this.shareRoute.getPhone()
      this.phoneNumber = phoneNumber

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.href = 'tel:' + phoneNumber
      } else {
        this.showPhoneNumber = !this.showPhoneNumber
      }
    }

  }
}
</script>