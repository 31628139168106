import axios from 'axios'
import {db, firebaseToken, messaging} from "@/shared/firebase"
import {onMessage} from "firebase/messaging";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {utils} from "@/shared/utils"


export default class ShareRouteRepository {

    scope = null

    constructor(scope) {
        this.scope = scope
    }

    async sendToken() {
        const url = 'https://drivers.api.losmoviles.xyz/subscribe_topic/v1'
        const token = await firebaseToken()
        const data = {
            token: token,
            topic: 'SharedRoute'
        }

        return axios.post(url, data, {redirect: 'follow'})
    }

    receivedRoute(serviceId) {
        onMessage(messaging, (payload) => {
            if (payload.data.service_id === serviceId) {
                this.scope.$bus.$emit('clearTimer')
                payload.data.route = JSON.parse(utils.keyBase64(payload.data.SharedRoute))
                this.scope.$bus.$emit('updateRoute', payload.data)
            }
        })
    }

    async requestShareFirestore(city, serviceID) {
        const url = `https://companies.api.losmoviles.xyz/share_route_ios/v1?cityId=${city}&serviceId=${serviceID}`

        return axios.get(url, {redirect: 'follow'})
    }

    async findRoute(city, serviceID) {
        const url = `https://companies.api.losmoviles.xyz/find_route/v1?cityId=${city}&serviceId=${serviceID}`

        return axios.get(url, {redirect: 'follow'})
    }

    subscribeRouteTopic(city, serviceId) {
        const topic = `/${city}/${process.env.VUE_APP_FIRESTORE_TOPIC_PRODUCTION}/share-route`
        const q = query(collection(db, topic), where("__name__", "==", serviceId))

        let data = null
        onSnapshot(q, (payload) => {
            payload.docChanges().forEach((change) => {
                data = change.doc.data()[serviceId]
                if (change.type === "added") {
                    this.scope.$bus.$emit('clearTimer')
                    data.route = JSON.parse(utils.keyBase64(data.SharedRoute))
                    this.scope.$bus.$emit('updateRoute', data)
                }

                if (change.type === "modified") {
                    data.route = JSON.parse(utils.keyBase64(data.SharedRoute))
                    this.scope.$bus.$emit('updateRoute', data)
                }

                if (change.type === "removed") {
                    this.scope.$bus.$emit('deleteRoute')
                }
            })
        })
    }
}
